import DateTime from "./DateTime";

// Converts between a form object compatible with the Vue frontend
// and an object submittable for the API

export default {
  formToSubmission(form) {
    // Deep clone form data
    const formStringified = JSON.stringify(Object.assign({}, form));
    const f = JSON.parse(formStringified);

    // Month / year
    const monthYear = DateTime.yearmonthstringToYearMonth(f.month);
    const year = monthYear[0];
    const month = monthYear[1];

    // Company
    const company = f.company;

    // Læskedrikke
    const laskedrikke = {};
    for (const prop in f.laskedrikke) {
      const item = f.laskedrikke[prop];
      laskedrikke[prop] = {
        l: _parseInputToSubmission(item.l),
        sugarfreel: _parseInputToSubmission(item.sugarfreel),
      };
    }

    // Hvide vande
    const hvidevande = {};
    for (const prop in f.hvidevande) {
      const item = f.hvidevande[prop];
      hvidevande[prop] = {
        l: _parseInputToSubmission(item.l),
      };
    }

    // Andre drikke
    const andredrikke = {};
    for (const prop in f.andredrikke) {
      const item = f.andredrikke[prop];
      andredrikke[prop] = {
        l: _parseInputToSubmission(item.l),
      };
    }

    // Ontrade
    const ontrade = {};
    for (const prop in f.ontrade) {
      const item = f.ontrade[prop];
      ontrade[prop] = {
        l: _parseInputToSubmission(item.l),
      };
    }

    const sub = {
      year,
      month,
      company,
      laskedrikke,
      hvidevande,
      andredrikke,
      ontrade,
    };

    return sub;
  },
  submissionToForm(form, submission) {
    const monthYearString = DateTime.yearMonthToYearmonthstring(
      submission.year,
      submission.month
    );
    form.month = monthYearString;
    form.company = submission.company;

    // Læskedrikke
    for (const prop in form.laskedrikke) {
      const item = submission.laskedrikke[prop];
      form.laskedrikke[prop].l = _parseSubmissionToInput(item.l);
      form.laskedrikke[prop].sugarfreel = _parseSubmissionToInput(
        item.sugarfreel
      );
    }

    // Hvide vande
    for (const prop in form.hvidevande) {
      const item = submission.hvidevande[prop];
      form.hvidevande[prop].l = _parseSubmissionToInput(item.l);
    }

    // Andre drikke
    for (const prop in form.andredrikke) {
      const item = submission.andredrikke[prop];
      form.andredrikke[prop].l = _parseSubmissionToInput(item.l);
    }

    // Ontrade
    for (const prop in form.ontrade) {
      const item = submission.ontrade[prop];
      form.ontrade[prop].l = _parseSubmissionToInput(item.l);
    }

    return form;
  },
  parseInputToSubmission: _parseInputToSubmission,
  parseSubmissionToInput: _parseSubmissionToInput,
};

function _parseInputToSubmission(value) {
  return parseFloat(value.replace(",", "."));
}

function _parseSubmissionToInput(value, round) {
  if (round) {
    const rounded = Math.round(value * 100) / 100;
    return rounded.toString().replace(".", ",");
  }
  return value.toString().replace(".", ",");
}
