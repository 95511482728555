<template>
  <v-container>
    <div
      id="softdrink-submissions"
      v-if="user && publishedMonths && companies && softdrinkSubmissions"
    >
      <v-card class="elevation-0">
        <v-card-title>
          <v-row class="justify-space-between">
            <v-col cols="2">
              <v-select
                :items="years"
                label="År"
                v-model="year"
                @change="yearChange"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                class="mr-6"
                v-model="search"
                append-icon="mdi-magnify"
                label="Søg"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="mt-3 text-end">
              <v-btn class="primary" @click="createNew">Opret ny</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          class="table-cursor"
          :headers="headers"
          :items="submissions"
          :search="search"
          dense
          :options="{ itemsPerPage: 10000 }"
          hide-default-footer
          no-data-text="Ingen indberetninger for år"
          @click:row="rowClick"
        ></v-data-table>
      </v-card>

      <v-dialog v-model="itemDialog" scrollable max-width="700px">
        <v-card>
          <v-card-title>{{ dialogHeader }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-form
              v-model="formValid"
              :disabled="dialogMode === 'edit' && !user.company.admin"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-menu
                      ref="menu"
                      v-model="monthMenu"
                      :close-on-content-click="false"
                      :return-value.sync="form.month"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.month"
                          label="Måned"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!user.company.admin"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.month"
                        type="month"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="monthMenu = false">
                          Annuller
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(form.month)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6"
                    ><v-select
                      v-show="user.company.admin"
                      :items="companies"
                      label="Bryggeri"
                      item-text="name"
                      item-value="_id"
                      v-model="form.company"
                    ></v-select
                  ></v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <h3>Læskedrikke</h3>
                  </v-col>
                  <v-col cols="8" class="text-right">
                    Sukkerfri - Højst 0,5 gram tilsat sukker per 100 ml
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider class="mb-3"></v-divider>
                </v-row>
                <v-row v-for="(drink, key) in form.laskedrikke" :key="key">
                  <v-col cols="12" md="4" class="pt-3">
                    <strong>{{ drink.title }}</strong>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      v-model="drink.l"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Liter"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      v-model="drink.sugarfreel"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Heraf sukkerfri liter"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mb-3">
                  <v-col cols="12" md="4" class="subtitle-1 font-weight-bold"
                    >Total læskedrikke</v-col
                  >
                  <v-col cols="12" md="4" class="subtitle-1 font-weight-bold">{{
                    laskedrikkeTotal
                  }}</v-col>
                  <v-col cols="12" md="4" class="subtitle-1 font-weight-bold">{{
                    laskedrikkeSugarfreeTotal
                  }}</v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>Hvide vande</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider class="mb-3"></v-divider>
                </v-row>
                <v-row v-for="(drink, key) in form.hvidevande" :key="key">
                  <v-col cols="12" md="4" class="pt-3">
                    <strong>{{ drink.title }}</strong>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      v-model="drink.l"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Liter"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mb-3">
                  <v-col cols="12" md="4" class="subtitle-1 font-weight-bold"
                    >Total Hvide vande</v-col
                  >
                  <v-col cols="12" md="4" class="subtitle-1 font-weight-bold">{{
                    hvidevandeTotal
                  }}</v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>Andre drikke</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider class="mb-3"></v-divider>
                </v-row>
                <v-row v-for="(drink, key) in form.andredrikke" :key="key">
                  <v-col cols="12" md="4" class="pt-3">
                    <strong>{{ drink.title }}</strong>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      v-model="drink.l"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Liter"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mb-3">
                  <v-col cols="12" md="4" class="subtitle-1 font-weight-bold"
                    >Total Andre drikke</v-col
                  >
                  <v-col cols="12" md="4" class="subtitle-1 font-weight-bold">{{
                    andredrikkeTotal
                  }}</v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <h3>On-trade</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider class="mb-3"></v-divider>
                </v-row>
                <v-row v-for="(drink, key) in form.ontrade" :key="key">
                  <v-col cols="12" md="4" class="pt-3">
                    <strong>{{ drink.title }}</strong>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      v-model="drink.l"
                      :rules="[formRules.requiredRule, formRules.numberRule]"
                      label="Liter"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-divider class="mb-3"></v-divider>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" md="4" class="subtitle-1 font-weight-bold"
                    >Total</v-col
                  >
                  <v-col cols="12" md="4" class="subtitle-1 font-weight-bold">{{
                    total
                  }}</v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-4" v-if="user.company.admin">
            <div class="d-flex justify-space-between" style="width: 100%">
              <div>
                <v-btn
                  v-if="dialogMode === 'edit'"
                  color="error"
                  text
                  @click="deleteClick"
                >
                  Slet
                </v-btn>
              </div>
              <div>
                <v-btn class="primary--text" text @click="itemDialog = false">
                  Annuller
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'new'"
                  class="primary ml-6"
                  @click="dialogConfirmCreate"
                >
                  Opret
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'edit'"
                  class="primary ml-6"
                  @click="dialogConfirmSave"
                >
                  Gem
                </v-btn>
              </div>
            </div>
          </v-card-actions>
          <v-card-actions class="py-4" v-if="!user.company.admin">
            <div class="d-flex justify-space-between" style="width: 100%">
              <div></div>
              <div>
                <v-btn
                  v-if="dialogMode === 'new'"
                  class="primary--text"
                  text
                  @click="itemDialog = false"
                >
                  Annuller
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'edit'"
                  class="primary--text"
                  text
                  @click="itemDialog = false"
                >
                  Luk
                </v-btn>
                <v-btn
                  v-if="dialogMode === 'new'"
                  class="primary ml-6"
                  @click="dialogConfirmCreate"
                >
                  Opret
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog" max-width="480">
        <v-card>
          <v-card-title class="text-h6">
            Er du sikker på du vil slette?
          </v-card-title>

          <v-card-text>
            Dette vil slette indberetningen permanent og kan ikke fortrydes.
          </v-card-text>

          <v-card-actions class="py-5">
            <v-spacer></v-spacer>

            <v-btn text @click="deleteDialog = false"> Annuller </v-btn>

            <v-btn class="error ml-6" dark text @click="dialogConfirmDelete">
              Slet
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import api from "../api/api";
import { mapState, mapActions } from "vuex";
import { required, number } from "../utils/FormRules";
import DateTime from "../utils/DateTime";
import SoftdrinkSubmission from "../utils/SoftdrinkSubmission";

export default {
  name: "SoftdrinkSubmissions",
  data() {
    return {
      monthpicker: null,
      monthMenu: false,
      itemDialog: false,
      dialogMode: null,
      deleteDialog: false,
      search: "",
      headers: [
        { text: "År", value: "year", align: "start" },
        { text: "Måned", value: "month" },
        { text: "Bryggeri", value: "company" },
      ],
      defaultForm: {
        company: null,
        month: null,
        laskedrikke: {
          cola: {
            title: "Cola",
            l: "0",
            sugarfreel: "0",
          },
          appelsin: {
            title: "Appelsin",
            l: "0",
            sugarfreel: "0",
          },
          citrus: {
            title: "Citrus",
            l: "0",
            sugarfreel: "0",
          },
          lemonlime: {
            title: "Lemon/Lime",
            l: "0",
            sugarfreel: "0",
          },
          iste: {
            title: "Iste",
            l: "0",
            sugarfreel: "0",
          },
          energidrik: {
            title: "Energidrik",
            l: "0",
            sugarfreel: "0",
          },
          vitaminvand: {
            title: "Vitaminvand",
            l: "0",
            sugarfreel: "0",
          },
          diverse: {
            title: "Diverse",
            l: "0",
            sugarfreel: "0",
          },
        },
        hvidevande: {
          udenbrusmedsmag: {
            title: "Uden brus, med smag",
            l: "0",
          },
          udenbrusudensmag: {
            title: "Uden brus, uden smag",
            l: "0",
          },
          medbrusmedsmag: {
            title: "Med brus, med smag",
            l: "0",
          },
          medbrusudensmag: {
            title: "Med brus, uden smag",
            l: "0",
          },
        },
        andredrikke: {
          cider: {
            title: "Cider (≤ 0,5% Vol.)",
            l: "0",
          },
          juice: {
            title: "Juice",
            l: "0",
          },
          mostogsaft: {
            title: "Most og saft",
            l: "0",
          },
          chokolademalk: {
            title: "Chokolademælk",
            l: "0",
          },
          ovrige: {
            title: "Øvrige",
            l: "0",
          },
        },
        ontrade: {
          ontrade: {
            title: "Heraf On-trade",
            l: "0",
          },
        },
      },
      form: {},
      formValid: false,
      formRules: {
        requiredRule: required,
        numberRule: number,
      },
      year: null,
    };
  },
  mounted() {
    const now = new Date();
    this.year = now.getFullYear().toString();

    this.loadPublishedMonths();
    this.loadCompanies();
    this.loadSoftdrinkSubmissions(this.year);
  },
  methods: {
    ...mapActions([
      "loadPublishedMonths",
      "loadCompanies",
      "loadSoftdrinkSubmissions",
      "createSoftdrinkSubmission",
      "saveSoftdrinkSubmission",
      "deleteSoftdrinkSubmission",
    ]),
    yearChange() {
      this.loadSoftdrinkSubmissions(this.year);
    },
    createNew() {
      // Deep clone of default form values
      const newFormStringified = JSON.stringify(
        Object.assign({}, this.defaultForm)
      );
      const newForm = JSON.parse(newFormStringified);

      // Set month/year and company
      const date = new Date(
        this.softdrinkPublishedMonth.endyear,
        this.softdrinkPublishedMonth.endmonth
      );
      const nextMonth = new Date(date.setMonth(date.getMonth() + 1));
      const monthYear = DateTime.yearMonthToYearmonthstring(
        nextMonth.getFullYear(),
        nextMonth.getMonth()
      );
      newForm.month = monthYear;
      newForm.company = this.user.company._id;

      this.form = newForm;
      this.dialogEditId = null;
      this.dialogMode = "new";
      this.itemDialog = true;
    },
    rowClick(row) {
      // Deep clone of default form values
      const newFormStringified = JSON.stringify(
        Object.assign({}, this.defaultForm)
      );
      const blankForm = JSON.parse(newFormStringified);

      this.form = blankForm;

      this.dialogMode = "edit";
      this.dialogEditId = row._id;
      this.itemDialog = true;

      api.getSoftdrinkSubmission(this.token, row._id).then((response) => {
        const newForm = SoftdrinkSubmission.submissionToForm(
          blankForm,
          response.data
        );
        this.form = newForm;
      });
    },
    deleteClick() {
      this.deleteDialog = true;
    },
    dialogConfirmCreate() {
      const newSubmission = SoftdrinkSubmission.formToSubmission(this.form);
      this.createSoftdrinkSubmission(newSubmission);
      this.itemDialog = false;
    },
    dialogConfirmSave() {
      // Deep clone of default form values
      const newFormStringified = JSON.stringify(Object.assign({}, this.form));
      const newForm = JSON.parse(newFormStringified);
      const submission = SoftdrinkSubmission.formToSubmission(newForm);

      this.saveSoftdrinkSubmission({
        submissionId: this.dialogEditId,
        submission: submission,
      });
      this.itemDialog = false;
    },
    dialogConfirmDelete() {
      this.deleteSoftdrinkSubmission({
        submissionId: this.dialogEditId,
        year: this.year,
      });
      this.deleteDialog = false;
      this.itemDialog = false;
    },
  },
  computed: {
    ...mapState([
      "loaded",
      "user",
      "token",
      "companies",
      "softdrinkSubmissions",
      "publishedMonths",
    ]),
    softdrinkPublishedMonth() {
      if (this.publishedMonths) {
        return this.publishedMonths.find((month) => {
          return month.type === "softdrink";
        });
      }

      return null;
    },
    submissions() {
      return this.softdrinkSubmissions.map((sub) => {
        return {
          _id: sub._id,
          year: sub.year,
          month: DateTime.monthnameFromIndex(sub.month),
          company: sub.company.name,
        };
      });
    },

    dialogHeader() {
      return this.dialogMode == "new" ? "Opret indberetning" : "Indberetning";
    },
    laskedrikkeTotal() {
      if (this.form.laskedrikke) {
        let result = 0;
        for (const prop in this.form.laskedrikke) {
          const item = this.form.laskedrikke[prop];
          result += SoftdrinkSubmission.parseInputToSubmission(item.l);
        }
        const formattedResult = SoftdrinkSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    laskedrikkeSugarfreeTotal() {
      if (this.form.laskedrikke) {
        let result = 0;
        for (const prop in this.form.laskedrikke) {
          const item = this.form.laskedrikke[prop];
          result += SoftdrinkSubmission.parseInputToSubmission(item.sugarfreel);
        }
        const formattedResult = SoftdrinkSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    hvidevandeTotal() {
      if (this.form.hvidevande) {
        let result = 0;
        for (const prop in this.form.hvidevande) {
          const item = this.form.hvidevande[prop];
          result += SoftdrinkSubmission.parseInputToSubmission(item.l);
        }
        const formattedResult = SoftdrinkSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    andredrikkeTotal() {
      if (this.form.andredrikke) {
        let result = 0;
        for (const prop in this.form.andredrikke) {
          const item = this.form.andredrikke[prop];
          result += SoftdrinkSubmission.parseInputToSubmission(item.l);
        }
        const formattedResult = SoftdrinkSubmission.parseSubmissionToInput(
          result,
          true
        );
        return isNaN(result) ? "-" : formattedResult;
      }
      return "0";
    },
    total() {
      const result =
        SoftdrinkSubmission.parseInputToSubmission(this.laskedrikkeTotal) +
        SoftdrinkSubmission.parseInputToSubmission(this.hvidevandeTotal) +
        SoftdrinkSubmission.parseInputToSubmission(this.andredrikkeTotal);
      const formattedResult = SoftdrinkSubmission.parseSubmissionToInput(
        result,
        true
      );
      return isNaN(result) ? "-" : formattedResult;
    },
    years() {
      const start = 2009;
      const end = parseInt(new Date().getFullYear());
      const diff = end - start + 1;

      return [...Array(diff)].map((_, i) => {
        return (end - i).toString();
      });
    },
  },
};
</script>
<style>
.table-cursor tbody tr:hover {
  cursor: pointer;
}
</style>
